.cns-brand-area {
    .title {
        font-size: 18px;
        font-weight: var(--p-bold);
        margin-bottom: 40px;
    }

    .cns-brand-list-style-2 {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        justify-content: space-between;
        gap: 70px;
        align-items: center;
        text-align: center;

        @media #{$md-layout} {
            grid-template-columns: repeat(4, 1fr);
            gap: 30px;
        }

        @media #{$sm-layout} {
            grid-template-columns: repeat(4, 1fr);
            gap: 30px;
        }

        @media #{$lg-layout} {
            grid-template-columns: repeat(4, 1fr);
            gap: 30px;
        }

        @media #{$large-mobile} {
            grid-template-columns: repeat(2, 1fr);
        }

        li {
            list-style: none;
            margin: 0 0;
        }
    }
}


.cns-brand-area-defalt {
    position: relative;
    z-index: 9;

    .section-title {
        .title {
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;

            @media #{$large-mobile} {
                font-size: 12px;
            }
        }
    }

    .cns-brand-list {
        margin: 0 0;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding: 0;
        text-align: center;
        align-items: center;
        gap: 80px;

        @media #{$large-mobile} {
            grid-template-columns: repeat(2, 1fr);
            gap: 22px 50px;
        }

        @media #{$sm-layout} {
            gap: 30px;
        }

        @media #{$lg-layout} {
            gap: 70px;
        }

        @media #{$laptop-device} {
            gap: 132px;
        }

        li {
            list-style: none;
            margin: 0 0;

            &:last-child {
                margin: 0;
            }

            @media #{$small-mobile} {
                text-align: center;
                margin: 0 0 30px 0;
            }

            img {
                max-height: 84px;
            }
        }
    }
}

.brand-slick-activition {
    a {
        height: 100%;
        display: flex;
        min-height: 50px;
        align-items: center;
        justify-content: center;
        img {
            max-height: 60px;
            min-height: 60px;
            text-align: center;
            margin: auto;
            padding: 5px;
            opacity: 0.8;
            transition: 0.3s;
        }
        &:hover{
            img{
                opacity: 1;
            }
        }
    }
}
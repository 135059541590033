/*--------------------------
    Header Styles  
 --------------------------*/
.logo-dark {
    display: none;
}

.header-default {
    max-width: 100%;
    background-color: var(--color-white);
    color: var(--color-body);
    font-size: 12px;
    z-index: 99;
    position: relative;
    .logo {
        a {
            display: flex;
            height: 80px;
            line-height: 80px;
            align-items: center;
            @media #{$sm-layout} {
                height: 56px;
                line-height: 56px;
            }

            img {
                max-height: 45px;
            }
        }
    }

    .header-right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &.header-relative {
        position: relative;
    }

    .header-right {
        display: flex;
        justify-content: space-between;

        .header-btn {
            margin-left: 25px;

            @media #{$lg-layout} {
                margin-left: 0;
            }

            .header-btn-reg {
                font-size: 14px;
                padding: 12px 24px;
                font-weight: 700;
                background: #B500A3;
                border-radius: 6px;
                color: var(--color-white);
                transition: all 0.3s linear;

                @media #{$large-mobile} {
                    font-size: 13px;
                    padding: 8px 16px;
                }

                &:hover {
                    transition: all 0.3s linear;
                    box-shadow: var(--shadow-primary);
                    -webkit-filter: brightness(125%);
                    filter: brightness(125%);
                    color: var(--color-white);
                    text-decoration: none;
                }
            }

            @media #{$md-layout} {
                margin-left: 0;
            }

            @media #{$sm-layout} {
                margin-left: 0;
            }
        }
    }

    a,
    button {
        &.btn-default {

            @media #{$sm-layout} {
                height: 36px;
                line-height: 35px;
                padding: 0 10px;
                font-size: 12px;
            }

            &.btn-small {
                @media #{$sm-layout} {
                    height: 36px;
                    line-height: 35px;
                    padding: 0 10px;
                    font-size: 12px;
                }
            }
        }
    }

    &.header-transparent {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: auto;
        background: transparent !important;
        backdrop-filter: inherit;
        border-bottom: 1px solid var(--shadow-primary-50);
    }
}



.header-transparent-with-topbar {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;

    @media #{$md-layout} {
        position: relative;
    }

    @media #{$sm-layout} {
        position: relative;
    }

    .header-top-bar {
        background-color: transparent;

        @media #{$md-layout} {
            background-color: var(--color-darker);
        }

        @media #{$sm-layout} {
            background-color: var(--color-darker);
        }
    }

    .header-default {
        background-color: transparent;
        background-image: none;

        @media #{$md-layout} {
            background-color: var(--color-darker);
        }

        @media #{$sm-layout} {
            background-color: var(--color-darker);
        }
    }
}



.hamberger-button {
    border: 0 none;
    color: var(--color-heading);
    background: none;
    width: 40px;
    height: 40px;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.header-left-align {
    .mainmenu-nav {
        margin-left: 30px;
    }
}



.dreamd-header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-white) !important;
    width: 100%;
    height: 80px;
    animation: headerSticky .95s ease forwards;
    z-index: 9;
    @media #{$sm-layout} {
        height: 56px;
    }
}


.dd-fluid-header {
    .container-wrapper {
        max-width: 1600px;
    }
}






@keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}
/* Button Styles  */

.button-group {
    margin: -10px;

    a,
    button {
        margin: 10px;
    }
}

a,
button,
div {
    &.btn-default {
        border: 0;
        padding: 0 32px;
        border-radius: 8px;
        background-color: transparent;
        background: var(--color-primary-600);
        transition-duration: 300ms;
        color: var(--color-white);
        font-size: 16px;
        font-weight: var(--p-medium);
        text-align: center;
        text-decoration: none;
        display: inline-block;
        height: 48px;
        line-height: 48px;
        width: auto;
        transition: 0.4s;

        @media #{$large-mobile} {
            padding: 0 100px;
        }

        &.has-right-icon {
            i {
                margin-left: 10px;
            }
        }
        &.has-left-icon {
            i {
                margin-right: 10px;
            }
        }
        &.btn-icon {
            .icon {
                position: relative;
                top: 2px;
                padding-left: 4px;

                i {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        &.color-green{
            background: var(--color-secondary-300);
            color: var(--color-heading);
            &:hover {
                box-shadow: none;
                -webkit-filter: brightness(105%);
                filter: brightness(105%);
                color: var(--color-heading);
                text-decoration: none;
            }
        }

        &:hover {
            box-shadow: var(--shadow-primary);
            -webkit-filter: brightness(125%);
            filter: brightness(125%);
            color: var(--color-white);
            text-decoration: none;
        }

        &.btn-small {
            height: 40px;
            line-height: 36px;
            padding: 0 24px;
            font-size: 14px;
            font-weight: var(--p-medium);
        }

        &.btn-medium {
            height: 65px;
            line-height: 61px;
            padding: 0 40px;
        }

        &.btn-large {
            height: 60px;
            line-height: 60px;
            padding: 0 36px;
            font-size: 17px;

            @media #{$sm-layout} {
                padding: 0 36px;
            }
        }

        &.btn-extra-large {
            height: 90px;
            line-height: 90px;
            padding: 0 70px;
            font-size: 20px;

            @media #{$sm-layout} {
                padding: 0 44px;
            }
        }

        &.btn-border {
            background-image: inherit;
            border-color: var(--color-primary-600);
            border-width: 2px;
            border-style: solid;
            color: var(--color-primary-600);
            background-color: transparent;
            &.btn-large{
                line-height: 56px;
            }
            &:hover {
                background-color: var(--color-primary-600);
                border-color: var(--color-primary-600);
                color: #fff;
                filter: none;
            }
        }

        &.text-underline {
            background-image: inherit;
            border-color: var(--color-white);

            span {
                position: relative;
                display: inline-block;
                line-height: 30px;
            }

            &:hover {
                box-shadow: none;
                background-color: var(--color-secondary);
                border-color: var(--color-secondary);
            }
        }

        &.round {
            border-radius: 100px;
        }

        &.square {
            border-radius: 0;
        }

        &.rounded-player {
            border-radius: 100%;
            width: 100px;
            height: 100px;
            padding: 0;
            line-height: 100px;
            font-size: 30px;
            display: inline-block;

            @media #{$sm-layout} {
                width: 70px;
                height: 70px;
                line-height: 70px;
            }

            span {
                i {
                    position: relative;
                    top: 1px;
                    left: 2px;
                }
            }

            &.border {
                background-image: inherit;
                border-color: var(--color-white) !important;
                border-width: 2px !important;

                &:hover {
                    background-color: var(--color-secondary);
                    border-color: var(--color-secondary) !important;
                }
            }

            &.sm-size {
                width: 70px;
                height: 70px;
                line-height: 70px;
            }
        }
    }
}

.form-group {
    .btn-default {
        padding: 0 25px;
    }
}

.hamberger-button-close {
    font-size: 30px;
    color: var(--color-primary);
    background: transparent;
    border: 0;
}

.btn-read-more {
    span {
        position: relative;
        color: var(--color-heading);
        display: inline-block;
        line-height: 35px;
        transition: 0.3s;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            background-color: var(--color-border);
            transition: 0.3s;
            width: 100%;
            bottom: 3px;
        }

        &::before {
            position: absolute;
            content: "";
            left: auto;
            bottom: 2px;
            background: var(--color-primary);
            width: 0;
            height: 2px;
            transition: 0.3s;
            right: 0;
        }
    }

    &:hover {
        span {
            color: var(--color-primary);

            &::before {
                width: 100%;
                left: 0;
                right: auto;
            }
        }
    }
}


.dreamd-badge {
    display: inline-block;
    background-color: var(--color-primary);
    padding: 0 16px;
    height: 30px;
    line-height: 30px;
    border-radius: 100px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.5px;
}

.dreamd-badge-card {
    background-color: transparent;
    background: transparent;
    padding: 1px 10px;
    border-radius: 100px;
    color: #fff;
    font-size: 12px;
    font-weight: var(--p-medium);
    display: inline-block;
    position: relative;
    z-index: 2;
    letter-spacing: 0.5px;

    &::before {
        background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: -1;
        border-radius: 100px;
        opacity: 0.5;
    }
}

.btn-collaborate-play {

    i {
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 500px;
        display: inline-block;
        background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95% / 200% 100%;

    }

    span {
        display: inline-block;
    }
}


.btn-collaborate-play {
    height: 30px;
    width: 30px;
    line-height: 30px;
    align-items: center;
    transition: var(--transition);

    span {
        margin-left: 15px;

    }

    &:hover {
        color: var(--color-white);
    }

}

.btn-link-dflt{
    border: 0;
    transition-duration: 300ms;
    color: var(--color-body);
    font-size: 16px;
    font-weight: var(--p-medium);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    width: auto;
    transition: 0.4s;
    &.has-right-icon {
        i {
            margin-left: 5px;
        }
    }
    &.has-left-icon {
        i {
            margin-right: 5px;
        }
    }
    &:hover {
        color: var(--color-primary-600);
    }
}